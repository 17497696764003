import React from 'react';
import Seo from '../components/seo';
import asset from '../lib/asset';
import Layout from '../components/layout';

export default function () {
  return (
    <Layout>
      <Seo title="Efesisos" />
      <h1>Un Estudio de la Carta de Efesios </h1>
      <p>
        Abajo encontrará un estudio de toda la carta de Efesios dado por Jason Henderson
        en el transcurso de dos a&ntilde;os en Los Estados Unidos. Las lecciones están
        siendo traducidas al espa&ntilde;ol y las estaremos publicando una a la vez,
        conforme vayan estando disponibles.{' '}
      </p>
      <h2>Efesios Capítulo 1</h2>
      <table
        className="sermon_table"
        width="514"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td>
              <div align="center">01</div>
            </td>
            <td>Efesios 1:1-2</td>
            <td>Gracia a Vosotros pt. 1 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/01Efesios_1_1-2.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">02</div>
            </td>
            <td>Efesios 1:1-2</td>
            <td>Gracia a Vosotros pt. 2 </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/02Efesios_1_1-2.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">03</div>
            </td>
            <td>Efesios 1:1-2</td>
            <td>Cristo Nuestra Paz </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/03Efesios_1_1-2.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">04</div>
            </td>
            <td>Efesios 1:3</td>
            <td>Bendiciones Espirituales en Cristo</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/04Efesios_1_3.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">05</div>
            </td>
            <td>Efesios 1:4-6</td>
            <td>Escogidos En El Amado </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/05Efesios_1_4-6.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">07</div>
            </td>
            <td>Efesios 1:8-9</td>
            <td>El Misterio de Su Voluntad </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/07Efesios_1_8-9.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">08</div>
            </td>
            <td>Efesios 1:10</td>
            <td>El Cumplimiento de Los Tiempos </td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/08Efesios_1_10.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">12</div>
            </td>
            <td>Efesios 1:15-16</td>
            <td>Fe, Amor, Oración</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/12Efesios_1_15-16.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">13</div>
            </td>
            <td>Efesios 1:17</td>
            <td>Espíritu de Sabiduría y Revelación</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/13Efesios_1_17.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">14</div>
            </td>
            <td>Efesios 1:18</td>
            <td>La Esperanza de Su Llamado</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/14Efesios_1_18.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">15</div>
            </td>
            <td>Efesios 1:19-21</td>
            <td>El Poder de Su Resurrección</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/15Efesios_1_19-21.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td width="29">
              <div align="center">16</div>
            </td>
            <td width="167">Efesios 1:22-23</td>
            <td width="267">La Iglesia, La Cual Es Su Plenitud</td>
            <td width="51">
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/16Efesios_1_12-23.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Efesios Capítulo 2</h2>
      <table
        className="sermon_table"
        width="514"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td width="29">
              <div align="center">17</div>
            </td>
            <td width="167">Efesios 2:1-3</td>
            <td width="267">La Condición del Hombre Adámico</td>
            <td width="51">
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/17Efesios_2_1-3.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">18</div>
            </td>
            <td>Efesios 2:4</td>
            <td>Su Gran Amor pt. 1</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/18Efesios_2_4.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">19</div>
            </td>
            <td>Efesios 2:4</td>
            <td>Su Gran Amor pt. 2</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/19Efesios_2_4.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">22</div>
            </td>
            <td>Efesios 2:8-10</td>
            <td>Creados Para Buenas Obras</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/22Efesios_2_8-10.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">23</div>
            </td>
            <td>Efesios 2:11-12</td>
            <td>El Pacto de La Circuncisión</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/23Efesios_2_11-12.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">24</div>
            </td>
            <td>Efesios 2:13-19</td>
            <td>Él Es Nuestra Paz</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/24Efesios_2_13-19.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">25</div>
            </td>
            <td>Efesios 2:20-22</td>
            <td>El Templo de Dios pt. 1</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/25Efesios_2_20-22.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">26</div>
            </td>
            <td>Efesios 2:20-22</td>
            <td>El Templo de Dios pt. 2</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/26Efesios_2_20-22.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Efesios Capítulo 3</h2>
      <table
        className="sermon_table"
        width="514"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td width="29">
              <div align="center">27</div>
            </td>
            <td width="167">Efesios 3:1-7</td>
            <td width="267">El Templo de Dios pt. 3</td>
            <td width="51">
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/27Efesios_3_1-7.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">28</div>
            </td>
            <td>Efesios 3:8-10</td>
            <td>El Templo de Dios pt. 4</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/28Efesios_3_8-10.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">30</div>
            </td>
            <td>Efesios 3:13-17</td>
            <td>El Espíritu En El Hombre Interior</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/30Efesios_3_13-17.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">31</div>
            </td>
            <td>Efesios 3:17-21</td>
            <td>La Plenitud de Dios</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/31Efesios_3_17-21.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Efesios Capítulo 4 </h2>
      <table
        className="sermon_table"
        width="514"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td width="29">
              <div align="center">32</div>
            </td>
            <td width="167">Efesios 4:1-3</td>
            <td width="267">La Unindad del Espíritu</td>
            <td width="51">
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/32Efesios_4_1-3.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">33</div>
            </td>
            <td>Efesios 4:4-5</td>
            <td>Una Misma Expectativa pt. 1</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/33Efesios_4_4-5.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">34</div>
            </td>
            <td>Efesios 4:4-5</td>
            <td>Una Misma Expectativa pt. 2</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/34Efesios_4_4-5.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">35</div>
            </td>
            <td>Efesios 4:5-6</td>
            <td>Un Bautismo</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/35Efesios_4_5-6.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">36</div>
            </td>
            <td>Efesios 4:7-10</td>
            <td>Llevó Cautiva La Cautividad 1</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/36Efesios_4_7-10.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">37</div>
            </td>
            <td>Efesios 4:7-10</td>
            <td>Llevó Cautiva La Cautividad 2</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/37Efesios_4_7-10.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">38</div>
            </td>
            <td>Efesios 4:7-10</td>
            <td>Llevó Cautiva La Cautividad 3</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/38Efesios_4_7-10.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">39</div>
            </td>
            <td>Efesios 4:10</td>
            <td>Para Llenarlo Todo</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/39Efesios_4_10.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">40</div>
            </td>
            <td>Efesios 4:11-12</td>
            <td>El Ministerio</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/40Efesios_4_11-12.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">41</div>
            </td>
            <td>Efesios 4:13</td>
            <td>Un Varón Perfecto</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/41Efesios_4_13.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">42</div>
            </td>
            <td>Efesios 4:13-15</td>
            <td>El Pensamiento Más Elevado de Dios</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/42Efesios_4_13-15.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">43</div>
            </td>
            <td>Efesios 4:16-19</td>
            <td>La Vanidad de La Mente</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/43Efesios_4_16-19.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">44</div>
            </td>
            <td>Efesios 4:20-21</td>
            <td>Aprendiendo a Christo 1</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/44Efesios_4_20-21.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">45</div>
            </td>
            <td>Efesios 4:20-21</td>
            <td>Aprendiendo a Christo 2</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/45Efesios_4_20-21.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">46</div>
            </td>
            <td>Efesios 4:22-24</td>
            <td>La Renovación de La Mente</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/46Efesios_4_22-24.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">49</div>
            </td>
            <td>Efesios 4:25</td>
            <td>Hablad La Verdad</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/49Efesios_4_25.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">50</div>
            </td>
            <td>Efesios 4:26</td>
            <td>El Lugar del Diablo</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/50Efesios_4_26.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">51</div>
            </td>
            <td>Efesios 4:27-32</td>
            <td>El Perdón</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/51Efesios_4_28-32.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Efesios Capítulo 5</h2>
      <table
        className="sermon_table"
        width="514"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td width="29">
              <div align="center">52</div>
            </td>
            <td width="167">Efesios 5:1-2</td>
            <td width="267">El Amor de Cristo</td>
            <td width="51">
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/52Efesios_5_1-2.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">53</div>
            </td>
            <td>Efesios 5:3-7</td>
            <td>Palabras Vanas</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/53Efesios_5_3-7.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">54</div>
            </td>
            <td>Efesios 5:8-14</td>
            <td>Ustedes Eran Tinieblas</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/54Efesios_5_8-14.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">55</div>
            </td>
            <td>Efesios 5:9-14</td>
            <td>Anden En La Luz</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/55Efesios_5_9-14.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">56</div>
            </td>
            <td>Efesios 5:15-16</td>
            <td>Aprovechando El Tiempo</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/56Efesios_5_15-16.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">57</div>
            </td>
            <td>Efesios 5:17-20</td>
            <td>Relaciones y Roles 1</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/57Efesios_5_17-20.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">58</div>
            </td>
            <td>Efesios 5:21</td>
            <td>Relaciones y Roles 2</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/58Efesios_5_21.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">59</div>
            </td>
            <td>Efesios 5:21</td>
            <td>Relaciones y Roles 3</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/59Efesios_5_21.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">60</div>
            </td>
            <td>Efesios 5:22-33</td>
            <td>Relaciones y Roles 4</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/60Efesios_5_22-33.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">61</div>
            </td>
            <td>Efesios 5:26</td>
            <td>Santificación</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/61Efesios_5_26.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Efesios Capítulo 6</h2>
      <table
        className="sermon_table"
        width="514"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td>
              <div align="center">62</div>
            </td>
            <td>Efesios 6:1-9</td>
            <td>Relaciones y Roles 5</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/62Efesios_6_1-9.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">63</div>
            </td>
            <td>Efesios 6:10-17</td>
            <td>Fortaleceos En El Señor</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/63Efesios_6_10-17.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div align="center">64</div>
            </td>
            <td>Efesios 6:18-19</td>
            <td>Oración 1</td>
            <td>
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/64Efesios_6_18-20.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td width="29">
              <div align="center">65</div>
            </td>
            <td width="167">Efesios 6:18-20</td>
            <td width="267">Oración 2</td>
            <td width="51">
              <div align="center"></div>
              <div align="center">
                <a href={asset('mensajes/Efesios/65Efesios_6_18-20.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  );
}
